import '../styles/index.scss';

AOS.init({
    once: true,
    duration: 600,
    disable: 'mobile'
});

$(".wrapper__header .header__hamburger").click(function(){
  $(".wrapper__header").toggleClass("toggled");
});

$('.holder__nav a').on('click', function(e) {
	e.preventDefault();
    $('.holder__content .tab')
        .removeClass('active')
        .filter('#' + $(this).attr('data-target'))
        .addClass('active');
});

$('.cluster__news .slider').slick({
	dots: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4500
});

$('.banner__slider').slick({
	dots: false,
	arrows: false,
	speed: 500,
	slidesToShow: 1,
	autoplay: true,
	autoplaySpeed: 5000
});

$('.partners .slider').slick({
   arrows: false,
   dots: false,
   autoplay: true,
   autoplaySpeed: 3000,
   speed: 500,
   infinite: true,
   slidesToShow: 5,
   slidesToScroll: 1,
   swipeToSlide: true,
    responsive: [
   {
     breakpoint: 1091,
     settings: {
       slidesToShow: 4,
       slidesToScroll: 1,
       infinite: true
     }
   },
   {
     breakpoint: 768,
     settings: {
       slidesToShow: 3,
       slidesToScroll: 1
     }
   },
   {
     breakpoint: 550,
     settings: {
       slidesToShow: 2,
       slidesToScroll: 1
     }
   }
 ]
});

